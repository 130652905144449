import { Link } from "react-router-dom";

const Hero = () => {
  const ferdaColor = "rgb(45, 150, 130)";

  return (
    <section className="bg-white">
      <div className="max-w-screen-xl px-4 py-20 mx-auto lg:py-32">
        <div className="text-center">
          <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
            Empowering Industries <br />
            with Revolutionary Technology
          </h1>
          <p className="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 lg:px-48">
            Ferda Technologies is a grassroots technology company dedicated to
            transforming industries with innovative software solutions. Our
            mission is to deliver cutting-edge technology that enhances
            efficiency and drives growth across multiple sectors.
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-[rgb(45,150,130)] rounded-lg hover:bg-[rgb(35,130,110)] focus:ring-4 focus:ring-[rgb(45,150,130)]"
              style={{ hover: { color: ferdaColor } }}
            >
              Get in Touch
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

import React from "react";

const Contact = () => {
  return (
    <section className="bg-gray-100 py-20">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Contact Us
        </h1>
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-8">
            <h2 className="text-2xl font-semibold text-[rgb(45,150,130)] mb-4">
              Ferda Technologies
            </h2>
            <p className="text-gray-600 mb-4">
              We're here to answer any questions you may have about our
              services. Reach out!
            </p>
            <div className="space-y-2">
              <p className="flex items-center text-gray-700">
                <svg
                  className="w-5 h-5 mr-2 text-[rgb(45,150,130)]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  ></path>
                </svg>
                admin@ferdatech.com
              </p>
              <p className="flex items-center text-gray-700">
                <svg
                  className="w-5 h-5 mr-2 text-[rgb(45,150,130)]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  ></path>
                </svg>
                (562) 550-8438
              </p>
              {/* <p className="flex items-center text-gray-700">
                <svg className="w-5 h-5 mr-2 text-[rgb(45,150,130)]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </svg>
                123 Tech Street, Innovation City, ST 12345
              </p> */}
            </div>
          </div>
          {/* <div className="bg-gray-50 px-8 py-4">
            <p className="text-gray-600 text-sm">
              Business Hours: Monday - Friday, 9:00 AM - 5:00 PM EST
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;

import Brands from "./Brands";
import Faqs from "./FAQs";
import Feature2 from "./Feature2";
import Features from "./Features";
import FreeTrial from "./FreeTrial";
import Hero from "./Hero";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";

const Home = (props) => {
  return (
    <>
      <Hero />
      {/* <Brands /> */}
      <Features />
      {/* <Feature2 /> */}
      {/* <Testimonials /> */}
      {/* <Pricing /> */}
      {/* <Faqs /> */}
      {/* <FreeTrial /> */}
    </>
  );
};

export default Home;

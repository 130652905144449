import React from "react";
import { motion } from "framer-motion";
// import truckingImage from "../../images/trucking-software.jpg";
// import repairImage from "../../images/repair-software.jpg";
// import manufacturingImage from "../../images/manufacturing-software.jpg";
// import healthcareImage from "../../images/healthcare-software.jpg";
// import retailImage from "../../images/retail-software.jpg";

import dash from "../../images/DASH.png";

const FeatureItem = ({ icon, text }) => (
  <motion.li
    className="flex space-x-3"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {icon}
    <span className="text-base font-medium leading-tight text-gray-900">
      {text}
    </span>
  </motion.li>
);

const FeatureSection = ({
  title,
  description,
  features,
  image,
  imageAlt,
  reverse = false,
}) => (
  <div
    className={`items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 ${
      reverse ? "lg:flex-row-reverse" : ""
    }`}
  >
    <motion.div
      className="text-gray-500 sm:text-lg"
      initial={{ opacity: 0, x: reverse ? 50 : -50 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">
        {title}
      </h2>
      <p className="mb-8 font-light lg:text-xl">{description}</p>
      <ul className="pt-8 space-y-5 border-t border-gray-200 my-7">
        {features.map((feature, index) => (
          <FeatureItem key={index} icon={feature.icon} text={feature.text} />
        ))}
      </ul>
    </motion.div>
    <motion.img
      className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
      src={image}
      alt={imageAlt}
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    />
  </div>
);

const Features = () => {
  const checkIcon = (
    <svg
      className="flex-shrink-0 w-5 h-5 text-[rgb(45,150,130)]"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const industries = [
    {
      title: "Futurizing the Trucking Industry",
      description: (
        <>
          <a
            href="https://attunelogic.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[rgb(45,150,130)] hover:underline"
          >
            Attune Logic
          </a>
          , a Ferda Technologies product, provides cutting-edge software
          solutions designed to streamline operations and boost efficiency in
          the trucking sector. Our comprehensive suite of tools addresses the
          unique challenges faced by transportation companies.
        </>
      ),
      features: [
        {
          icon: checkIcon,
          text: "Real-time GPS tracking and route optimization",
        },
        { icon: checkIcon, text: "Automated dispatch and load management" },
        { icon: checkIcon, text: "Electronic logging device (ELD) compliance" },
        { icon: checkIcon, text: "Fuel efficiency monitoring and reporting" },
        { icon: checkIcon, text: "Driver performance analytics" },
      ],
      image: dash,
      imageAlt: "Trucking software dashboard",
    },
    // {
    //   title: "Empowering Service and Repair Businesses",
    //   description:
    //     "Our innovative software for the service and repair industry helps businesses manage their operations more effectively. From work order management to customer communications, Ferda Technologies has you covered.",
    //   features: [
    //     { icon: checkIcon, text: "Digital work order management" },
    //     { icon: checkIcon, text: "Inventory tracking and parts ordering" },
    //     { icon: checkIcon, text: "Customer relationship management (CRM)" },
    //     { icon: checkIcon, text: "Automated scheduling and dispatching" },
    //     { icon: checkIcon, text: "Mobile app for technicians" },
    //   ],
    //   // image: repairImage,
    //   // imageAlt: "Repair shop software interface",
    // },
    // {
    //   title: "Optimizing Manufacturing Processes",
    //   description:
    //     "Ferda Technologies offers robust solutions for the manufacturing industry, helping businesses streamline their production processes, manage inventory, and improve overall efficiency.",
    //   features: [
    //     { icon: checkIcon, text: "Production planning and scheduling" },
    //     { icon: checkIcon, text: "Quality control and assurance" },
    //     { icon: checkIcon, text: "Supply chain management" },
    //     { icon: checkIcon, text: "Equipment maintenance tracking" },
    //     { icon: checkIcon, text: "Real-time production monitoring" },
    //   ],
    //   // image: manufacturingImage,
    //   // imageAlt: "Manufacturing software dashboard",
    // },
    // {
    //   title: "Advancing Healthcare Management",
    //   description:
    //     "Our healthcare software solutions are designed to improve patient care, streamline administrative tasks, and ensure compliance with industry regulations.",
    //   features: [
    //     { icon: checkIcon, text: "Electronic Health Records (EHR) management" },
    //     { icon: checkIcon, text: "Appointment scheduling and reminders" },
    //     { icon: checkIcon, text: "Billing and insurance claim processing" },
    //     { icon: checkIcon, text: "Telemedicine integration" },
    //     { icon: checkIcon, text: "HIPAA-compliant data security" },
    //   ],
    //   // image: healthcareImage,
    //   // imageAlt: "Healthcare management software interface",
    // },
    // {
    //   title: "Enhancing Retail Operations",
    //   description:
    //     "Ferda Technologies provides comprehensive retail management solutions to help businesses boost sales, manage inventory, and enhance customer experiences.",
    //   features: [
    //     { icon: checkIcon, text: "Point of Sale (POS) systems" },
    //     { icon: checkIcon, text: "Inventory management and forecasting" },
    //     { icon: checkIcon, text: "Customer loyalty programs" },
    //     { icon: checkIcon, text: "E-commerce integration" },
    //     { icon: checkIcon, text: "Sales analytics and reporting" },
    //   ],
    //   // image: retailImage,
    //   // imageAlt: "Retail management software dashboard",
    // },
  ];

  return (
    <section className="bg-gray-50">
      <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
        {industries.map((industry, index) => (
          <FeatureSection
            key={index}
            title={industry.title}
            description={industry.description}
            features={industry.features}
            image={industry.image}
            imageAlt={industry.imageAlt}
            reverse={index % 2 !== 0}
          />
        ))}
      </div>
    </section>
  );
};

export default Features;
